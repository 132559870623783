<template>
  <div id="app">
    <SwNavbar />
    <main v-if="isLoaded">
      <router-view :key="$route.fullPath" />
    </main>
    <swLoader v-else />
    <footer>
      <SwFooter />
    </footer>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import SwFooter from './navigations/footer/sw-footer.vue';
import SwNavbar from './navigations/navbar/sw-navbar.vue';
import swLoader from './components/sw-loader.vue';
export default {
  components: {
    SwFooter,
    swLoader,
    SwNavbar,
  },
  setup(props, context) {
    const isLoaded = ref(false);

    const isDarkTheme = ref(false);
    const navBar = ref(null);
    const secondaryNav = ref(false)
    onMounted(() => {
      isLoaded.value = true
    })
    return {
      navBar,
      isDarkTheme,
      isLoaded,
      secondaryNav
    };
  },
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  overflow-x: hidden;  /* Prevent horizontal scrolling */
  overflow-y: auto;    /* Enable vertical scrolling */
}

#app {
  display: flex;
  flex-direction: column;
  color: #085248;
  height: 100%; /* Remove width: 100vw */
}

main {
  flex-grow: 1;
  margin: 4% 10px;
  box-sizing: border-box;
}

footer {
  margin-top: auto;
  height: 50px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: white;
  border-bottom: 1px solid #ddd;
}

</style>
