<template>
  <div class="sw-loader">
    <img
      :src="require('../assets/saralweb-icon-512x512.png')"
      placeholder="img"
    />
  </div>
</template>

<script>
export default {
  name: 'SwLoader',
  setup() {
    return {};
  },
};
</script>

<style>
.sw-loader {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 5px solid rgba(0, 128, 0, 0.7);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.sw-loader:before {
  content: "";
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border-top: 5px solid #f6f6f6;
  position: absolute;
  animation: rt 1.2s infinite;
  top: -5px;
  left: -5px;
}

@keyframes rt {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sw-loader img {
  width: 75px;
  height: 75px;
  position: fixed;
  padding-top: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
