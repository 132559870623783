const useMail = () => {
  function openMail(data) {
    const subject = encodeURIComponent('Mail To Saralweb');
    let body = '';
    const footer = `
      \n\n----------\n
      Sender Name: ${data?.name ? data?.name : ''}
      Sender Email: ${data?.email ? data?.email : ''}
      Sender Number: ${data?.phone ? data?.phone : ''}

    `;

    body = encodeURIComponent(`(If you wish to know more about SARALWEB's products, please share your organization's name, contact details, and your requirements. Our team will connect with you.)\n\n
       ${data?.msg ? data?.msg : ''}\n
      ` + footer);


    window.location.href = `mailto:info@saralweb.com?subject=${subject}&body=${body}`;
  }

  return { openMail };
};

export default useMail;
