const useMenuList = () => {
  const menuList = [
    { name: 'labour_compliance', iconName: 'gi-injustice', label:'Labour Compliance' },
    { name: 'statutory_reports', iconName: 'gi-progression', label:'Statutory Reports' },
    { name: 'research_notes', iconName: 'gi-notebook', label:'Research Notes' },
  ]

  function getMenuList() {
    return menuList
  }

  return { getMenuList }
}

export default useMenuList
