const useFooterLinksList = () => {

  const footerLinksList = [
    {
      groupName:'Links',
      links:[

        {
          label: 'Reach Us',
          name:'reach_us'
        },
        { name: 'research_notes',
          label:'Research Notes'
        },


      ]
    },
    {
      groupName:'Tools',
      links:[
        {
          label: 'IR Forums',
          name:'ir_forums'
        },
        {
          label: 'Labour Codes',
          name:'labour_codes'
        }
      ]
    },
    {
      groupName:'Saralweb',
      links:[
        {
          label: 'About Us',
          name:'about_us'
        },
        {
          label: 'Terms',
          name:'terms'

        },
        {
          label: 'Privacy Policy',
          name:'privacy_policy'

        }
      ]
    },
    {
      groupName:'Resources',
      links:[
        {
          label: 'Blogs',
          name:'blogs'
        },
        {
          label: 'Docs',
          name:'support_docs'

        },
        {
          label: 'Current Openings',
          name:'careers'

        }
      ]
    }

  ]

  function getFooterLinksList() {
    return footerLinksList
  }

  return { getFooterLinksList }
}

export default useFooterLinksList
