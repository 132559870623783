<template>
  <nav
    class="navbar d-flex justify-content-between"
    :class="{ 'navbar-scrolled': scrolled }"
  >
    <div @click.prevent="returnHome">
      <img
        class="home-btn-big"
        src="../../assets/logo.png"
      />
    </div>
    <div class="d-flex">
      <div
        class="nav-item me-2 my-auto"
        @click="gotoPage('reach_us')"
      >
        <v-icon
          name="md-phoneintalk"
          scale="1.1"
          class="ms-2"
          fill="#2f688d"
        />
        <span class="ms-1 my-auto">Contact Us</span>
      </div>
      <div
        class="nav-item me-2"
        @click="gotoPage('login')"
      >
        <v-icon
          name="md-login-twotone"
          scale="1.1"
          class="ms-2"
          fill="#2f688d"
        />
        <span class="ms-1">Log In</span>
      </div>
    </div>
  </nav>
</template>


<script>
import logo from '@/assets/images/logo.png'
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import useMenuList from '../../composables/useMenuList';
export default {
  name: 'SwNavbar',
  components: {

  },
  props: {
    actions: {
      type: Object,
      default: null
    }
  },
  emits: ['toggle-nav'],
  setup(props, context) {
    //variables
    const router = useRouter()
    const { getMenuList } = useMenuList()
    const scrolled = ref(false)
    const menuOpen = ref(false)
    const navBar = ref(null)
    const menuBar = ref()
    const menuList = getMenuList()


    const menuIcon = computed(() => {
      return menuOpen.value ? 'bi-x' : 'bi-list'
    })

    function returnHome() {
      router.push('/')
    }
    function toggleNavMenu() {
      menuOpen.value = !menuOpen.value
      context.emit('toggle-nav', menuOpen.value)
    }

    function gotoPage(name) {
      menuOpen.value = false
      if (name == 'login') {
        window.open('https://app.saralweb.com/', '_blank').focus();
      }
      else {
        router.push(`/${name}`)
      }
      //TODO:add proper links for pages
    }
    const handleScroll = () => {
      scrolled.value = window.scrollY > 0;
    };
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      logo,
      returnHome,
      scrolled,
      toggleNavMenu,
      menuIcon,
      menuOpen,
      menuList,
      gotoPage,
      navBar,
      menuBar
    }
  }
}
</script>

<style scoped>
.home-btn {
    cursor: pointer;
    text-align: center;
    height: 3vh;
    width: 3vh;
    background-color: white;
    margin-left: 5px;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
}
.home-btn-big {
    cursor: pointer;
    text-align: center;
    height: 4vh;
    background-color: white;
    margin-left: 5px;
    padding: 2px;
    cursor: pointer;
}

.navbar-toggler {
    border: none;
    padding: 0px;
}

.nav-item {
    cursor: pointer;
    color: #2f688d !important;
}

.nav-item:hover {
    color: lightgreen !important;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 6px;
    justify-content: end;
    background-color: white;
}

.navbar-toggler {
    border: none;
    padding: 0;
}

.nav-item {
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;

}

.nav-item:hover {
    color: #5D9D46;
}


@media (max-width: 480px) {

.home-btn-big {
  height: 3vh;
}

.footer{
  padding: 10px;
  font-weight: 400;
  text-align: center;

}

}


</style>
