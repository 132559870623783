import { createRouter, createWebHistory } from 'vue-router';
import { useHead } from '@unhead/vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: guardRoute,
    component: () => import('../views/Home.vue')
  },
  {
    path: '/research_notes',
    name: 'research_notes',
    beforeEnter: guardRoute,
    component: () => import('../views/ResearchNotes.vue')
  },
  {
    path: '/blogs',
    name: 'blogs',
    beforeEnter: guardRoute,
    component: () => import('../views/SaralwebBlogs.vue')
  },
  {
    path: '/blogs/:name',
    name: 'Blogs',
    component: () => import('../views/mdToHtml.vue'),
    meta: { dynamicMeta: [] }
  },
  {
    path: '/codes/:name',
    name: 'Codes',
    component: () => import('../views/mdToHtml.vue')
  },
  {
    path: '/about_us',
    name: 'about_us',
    beforeEnter: guardRoute,
    component: () => import('../views/Aboutus.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    beforeEnter: guardRoute,
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    beforeEnter: guardRoute,
    component: () => import('../views/privacyPolicy.vue')
  },
  {
    path: '/careers/:name',
    name: 'Careers',
    component: () => import('../views/mdToHtml.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    beforeEnter: guardRoute,
    component: () => import('../views/CareerPage.vue')
  },
  {
    path: '/reach_us',
    name: 'reach_us',
    beforeEnter: guardRoute,
    component: () => import('../sections/ContactUs.vue')
  },
  {
    path: '/labour_codes',
    name: 'labour_codes',
    beforeEnter: guardRoute,
    component: () => import('../sections/LabourCodes.vue')
  },
  {
    path: '/statutory_reports',
    name: 'statutory_reports',
    beforeEnter: guardRoute,
    component: () => import('../views/StatutoryReports.vue')
  },
  // {
  //   path: '/minimum_wages',
  //   name: 'minimum_wages',
  //   beforeEnter: guardRoute,
  //   component: () => import('../views/MinimumWages.vue')
  // },
  {
    path: '/support_docs',
    name: 'support_docs',
    beforeEnter(to, from, next) {
      console.log(to);
      const vuePressPath = '/saralweb';
      window.location.href = vuePressPath;
      next(false);
    }
  }
]


const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
  routes
})

function guardRoute(to, from, next) {
  //if any guard
  next()
}


router.beforeEach(async(to, from, next) => {
  //if any actions before each route
  const baseUrl = 'https://www.saralweb.com';

  const canonicalUrl = to.path.includes(':name') ? `${baseUrl}${to.fullPath}` : `${baseUrl}${to.path}`;
  useHead({
    link: [
      {
        rel: 'canonical',
        href: canonicalUrl,
      },
    ],
  });
  return next();
})

export default router;
