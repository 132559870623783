<template>
  <div class="footer">
    <div class="row">
      <hr class="hr">
      <div class="my-auto col-sm-4 footer-socials">
        <a
          href="https://x.com/saralweb"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../assets/svg/twiteernew.svg" />
        </a>
        <a
          href="https://www.linkedin.com/company/saralweb-com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../assets/svg/linkedInNew.svg" />
        </a>
        <a
          href="https://www.youtube.com/@saralweb2446"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../assets/svg/newyt.svg" />
        </a>
        <a
          href="https://www.facebook.com/Saralweb/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../assets/svg/fbnew.svg" />
        </a>
        <div
          class="mt-2 mail-div"
          @click="openMailToSend"
        >
          <v-icon
            name="md-email-round"
            scale="1.2"
            class="ms-2"
          />
          <span>info@saralweb.com</span>
        </div>
      </div>

      <div
        v-for="(item, index) in footerLinks"
        :key="index"
        class="col-sm-2 mt-4"
      >
        <div
          class="link-group-header d-flex"
          @click="expandFooterOrNot(item)"
        >
          <div>
            {{ item.groupName }}
          </div>
          <div class="d-block d-md-none">
            <v-icon
              :name="item.expand?'md-expandless-sharp':'md-expandmore-sharp'"
              scale="1"
              fill="black"
            />
          </div>
        </div>
        <div
          v-for="(links, linkIndex) in item.links"
          :key="linkIndex"
          class="mt-3"
          :class="{ 'd-block': item.expand,
                    'd-none d-sm-block':!item.expand }"
        >
          <div
            class="links"
            @click="gotoLink(links.name)"
          >
            {{ links.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copy-right">
    &copy; 2024 Saralweb. All rights reserved.
  </div>
</template>

<script>
import useFooterLinksList from '../../composables/useFooterLinksList'
import { useRouter } from 'vue-router'
import useMail from '../../composables/useMail';

import { ref } from 'vue'
export default {
  name: 'SwFooter',
  setup() {
    const router = useRouter()
    const {openMail} = useMail()
    const { getFooterLinksList } = useFooterLinksList()
    const footerLinks = ref(getFooterLinksList())
    function gotoLink(name) {
      if (name == 'ir_forums') {
        const url = 'https://www.linkedin.com/groups/9110034/'
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();

      }
      else {
        router.push(`/${name}`)

      }
    }

    function expandFooterOrNot(item) {
      item.expand = !item.expand
    }
    function openMailToSend() {
      openMail()
    }


    return {
      footerLinks,
      gotoLink,
      expandFooterOrNot,
      openMailToSend

    }
  }
}
</script>

  <style scoped>
    .footer {
    padding: 10px;
    font-weight: 400;
    width: 100vw;
    text-align: left;
    /* background-color: #e9f3eb; */
    }
    .footer-socials{
      text-align: center;
    }

    .footer-socials img {
    width: 35px;
    margin-left: 10px;

    }
.mail-div{
  cursor: pointer;
}
.hr{
  color:#6D94B0;
}
.mail-div :hover{
  color:#6D94B0 !important;
  text-decoration: underline;
}
    .footer .links {
    cursor: pointer;
    }

    .footer .links:hover {
    font-weight: 700;
    }

    .link-group-header {
    font-weight: 600;
    color:rgb(9, 1, 1);
    }

    .copy-right {
    text-align: center;
    color: white;
    background-color: #6D94B0;
    font-size: 0.7rem;
    padding: 2px;
    }


@media screen and (max-width: 650px) {

    .link-group-header {
    font-weight: 600;
    color: black;
    justify-content: center;

    }

    .footer {
    padding: 10px;
    font-weight: 400;
    text-align: center;

    }

    }
  </style>
