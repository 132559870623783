import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'

//bootstrap css only
import 'bootstrap/dist/css/bootstrap.min.css'


//bootstrap icons

import {
  MdEmailRound,
  MdPhoneintalk,
  MdLoginTwotone,
  MdExpandlessSharp,
  MdExpandmoreSharp,
  MdLocationonRound,
  MdSendRound,
  MdSearchRound,
  MdFilterlistTwotone,
  MdPersonaddalt} from 'oh-vue-icons/icons/md'

addIcons(
  MdEmailRound,
  MdPhoneintalk,
  MdLoginTwotone,
  MdExpandlessSharp,
  MdExpandmoreSharp,
  MdLocationonRound,
  MdSendRound,
  MdPersonaddalt,
  MdFilterlistTwotone,
  MdSearchRound
)
//npm oh-vue icons for customizable icons
import { OhVueIcon, addIcons } from 'oh-vue-icons'
import { createHead } from '@unhead/vue'


const head = createHead()

const app = createApp(App)

app.component('VIcon', OhVueIcon)
app.use(head)
app.use(router).mount('#app')
